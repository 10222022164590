<template>
  <div class="">
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    NAME
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-center text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    # PERMISSIONS
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only"></span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="roles" class="bg-white divide-y divide-gray-200">
                <tr v-for="(role, i) in roles.data" :key="i">
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ role.id }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ role.display_name }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    {{ role.permissions }}
                  </td>

                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
                  >
                    <router-link
                      :to="`/settings/managerole/${role.id}`"
                      class="text-indigo-600 hover:text-indigo-900"
                      >Edit</router-link
                    >
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>

            <div class="border-t" v-if="roles">
              <pagination :data="roles" :midSize="2" @clicked="loadData">
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "../../components/Pagination";
import checkPermissionExist from "@/mixins/checkPermissionExist";
// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",
  components: { Pagination },
  mixins: [checkPermissionExist],
  layout: "settings",

  data: () => ({
    roles: null,
  }),

  created() {},

  mounted() {
    // if (!this.checkPermissionExist(this.user, "manage-permissions")) {
    //   this.$router.go(-1);
    //   return false;
    // }

    var _this = this;
    _this.loadData(1);
  },

  methods: {
    async loadData(pageNo, filter) {
      // Submit the form
      if (filter) {
        // filter = filter;
        this.searching = true;
      } else {
        filter = "";
        this.searching = false;
      }

      try {
        const { data } = await axios.get(
          `/roles?page=${pageNo}&filter=${filter}`
        );

        console.log(data);

        this.roles = data;
        this.searching = false;

        // console.log("The current page is: ", this.agents.meta.current_page);

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },
  },
};
</script>